#root {
  display: flex;
  justify-content: center;
  padding: 3%;
  font-size: 14px;
}

.nav-pills .nav-link:hover{
  text-decoration: none;
  background-color: #eee;
}

.flex-column.nav.nav-pills {
    width: 15%;
    float: left;
    margin-top: 31px;
}

.loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-container{
  width: 80%
}

.my_table {
  margin-left: 20%;
}

.nav-item{
  width: 100%
}

.table {
  text-align: center;
}

.table td, .table th{
  padding: .4rem !important;
}

.align-left{
  text-align: left;
}